@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@include mat.core();

$maderopalette: (
  50: #faf8ed,
  100: #f2ecd3,
  200: #eae0b5,
  300: #e1d497,
  400: #daca81,
  500: #d4c16b,
  600: #cfbb63,
  700: #c9b358,
  800: #c3ab4e,
  900: #b99e3c,
  A100: #ffffff,
  A200: #fff5d4,
  A400: #ffeaa1,
  A700: #ffe488,
  contrast: (
    50: #ffffff,
    100: #ffffff,
  ),
);

$madero-typography: mat.define-typography-config(
  $font-family: "Roboto, sans-serif",
);

$primary: mat.define-palette($maderopalette, 500);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$theme: mat.define-light-theme(
    (
        color: (
        primary: $primary,
        accent: $accent,
        ),
        typography: $madero-typography,
        density: 0,
    )
);

@include mat.core-theme($theme);
@include mat.button-theme($theme);
@include mat.button-typography($madero-typography);
@include mat.typography-hierarchy($madero-typography);
@include mat.all-component-themes($theme);


:root {
    --opacity-8: 0.8;
  
    --white: #ffffff;
    --black: #000000;
  
    --transparent: transparent;
    --primary: #d4c16b;
    --accent: #ffffff;
    --primary-smoth: rgba(212, 193, 107, 0.2);
    --primary-smoth-8: rgba(212, 193, 107, 0.8);
  
    --primary-opacity-8: rgba(5, 64, 211, var(--opacity-8));
  
    --support-color-1: #03b6d6;
    --support-color-1-alpha: #03b6d639;
  
    --neutral-default: #efefef;
    --neutral-default-opacity-8: rgba(239, 239, 239, var(--opacity-8));
    --neutral-darkest: #54565b;
    --neutral-dark: #686868;
    --neutral-medium: #cdcdcd;
    --neutral-default-loading: rgba(0, 0, 0, 0.062);
  
    --suport-1: #6b6b6b;
    --suport-2: #16b138;
    --suport-3: #ffb400;
    --suport-4: #ee3f4d;
    --suport-5: #7ad16b;
    --suport-6: #a129d8;
  
  
    --theme-background: #ffffff;
    --theme-color: #000000;
  }
  

body { margin: 0; }
